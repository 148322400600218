import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { StructuredText } from '@/components/structured-text';
import type { TextBlockType } from '@/lib/types';

const TextBlock = ({ content, theme, anchor }: TextBlockType): ReactElement => (
  <BaseBlock className="text-block" width="Narrow" theme={theme} anchor={anchor} hasContentPadding={theme === 'Mid'}>
    <StructuredText data={content} />
  </BaseBlock>
);

export { TextBlock };
